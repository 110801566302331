<template>
  <b-card v-if="users" no-body class="card-company-table">
    <vue-good-table
      theme="polar-bear"
      :rows="users"
      :columns="fields"
      :line-numbers="true"
      :sort-options="{
        enabled: false,
      }"
    >
      <div slot="emptystate" class="text-nowrap text-center">
        Kayıt Bulunamadı
      </div>
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field === 'createdAt'" class="text-nowrap">
          <span class="font-weight-bolder mb-12">{{
            props.row.createdAt
          }}</span>
        </div>
        <div v-else-if="props.column.field === 'isActive'" class="text-nowrap">
          <b-badge
            pill
            :variant="props.row.isActive ? 'light-success' : 'light-danger'"
          >
            {{ props.row.isActive ? "AKTİF" : "PASİF" }}
          </b-badge>
        </div>
        <div v-else-if="props.column.field === 'actions'" class="text-nowrap">
          <feather-icon
            :id="`package-row-${props.row.id}-delete-icon`"
            icon="TrashIcon"
            size="16"
            class="mx-1"
            @click="removeUser(props.row)"
          />
          <feather-icon
            :id="`package-row-${props.row.id}-detail-icon`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="editUser(props.row)"
          />
        </div>
      </template>
    </vue-good-table>

    <!--Open Add User-->
    <b-sidebar
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      v-model="addUserPanel"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Yeni Kullanıcı Ekle</h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <b-form class="p-2" @submit.prevent>
          <b-form-group label="Öğrenci ?" label-for="vue-select">
            <v-select
              id="vue-select"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(branch) => branch.id"
              :options="branchs"
              v-model="addUserRequest.branchId"
              :getOptionLabel="
                (option) => option.firstName + ' ' + option.lastName
              "
            />
          </b-form-group>

          <b-form-group label="Kullanıcı Adı" label-for="name">
            <b-form-input
              name="firstName"
              id="name"
              type="text"
              v-model="addUserRequest.firstName"
            />
          </b-form-group>

          <b-form-group label="Kullanıcı Soyadı" label-for="surname">
            <b-form-input
              name="lastName"
              id="surname"
              type="text"
              v-model="addUserRequest.lastName"
            />
          </b-form-group>

          <b-form-group label="Kullanıcı Numarası" label-for="code">
            <b-form-input
              id="code"
              type="text"
              v-model="addUserRequest.idNumber"
            />
          </b-form-group>

          <b-form-group label="E-Posta Adresi" label-for="email">
            <b-form-input
              id="email"
              type="email"
              v-model="addUserRequest.email"
            />
          </b-form-group>

          <b-form-group label="Telefon Numarası" label-for="number">
            <b-input-group>
              <b-input-group-prepend is-text> (+90) </b-input-group-prepend>
              <cleave
                id="phone"
                v-model="addUserRequest.gsmNumber"
                trim
                class="form-control"
                :raw="false"
                :options="{
                  phone: true,
                  phoneRegionCode: 'TR',
                }"
                placeholder="5455948640"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group label="Şifre" label-for="password">
            <b-form-input
              id="password"
              type="password"
              v-model="addUserRequest.password"
            />
          </b-form-group>

          <b-form-group label="Cinsiyesi" label-for="from">
            <v-select
              id="vue-select"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(gender) => gender.name"
              label="name"
              :options="gender"
              v-model="addUserRequest.gender"
            />
          </b-form-group>
          <b-form-group label="Bilgilendirme" label-for="from">
            <b-form-checkbox v-model="addUserRequest.campaignEmail">
              E-Posta Bilgilendirme
            </b-form-checkbox>
            <b-form-checkbox v-model="addUserRequest.campaignSms">
              Sms Bilgilendirme
            </b-form-checkbox>
            <b-form-checkbox v-model="addUserRequest.campaignPhone">
              Aranarak Bilgilendirme
            </b-form-checkbox>
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              @click="addBranchUser"
            >
              Ekle
            </b-button>
            <b-button variant="outline-secondary" @click="hide">
              Vazgeç
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>

    <b-sidebar
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      v-model="editUserPanel"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Veli Düzenleme</h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <b-form class="p-2" @submit.prevent>
          <b-form-group label="Kullanıcı Adı" label-for="name">
            <b-form-input
              name="firstName"
              id="name"
              type="text"
              v-model="editUserRequest.firstName"
            />
          </b-form-group>

          <b-form-group label="Kullanıcı Soyadı" label-for="surname">
            <b-form-input
              name="lastName"
              id="surname"
              type="text"
              v-model="editUserRequest.lastName"
            />
          </b-form-group>

          <b-form-group label="Kullanıcı Numarası" label-for="code">
            <b-form-input
              id="code"
              type="text"
              v-model="editUserRequest.idNumber"
            />
          </b-form-group>

          <b-form-group label="E-Posta Adresi" label-for="email">
            <b-form-input
              id="email"
              type="email"
              v-model="editUserRequest.email"
            />
          </b-form-group>

          <b-form-group label="Telefon Numarası" label-for="number">
            <b-input-group>
              <b-input-group-prepend is-text> (+90) </b-input-group-prepend>
              <cleave
                id="phone"
                v-model="editUserRequest.gsmNumber"
                trim
                class="form-control"
                :raw="false"
                :options="{
                  phone: true,
                  phoneRegionCode: 'TR',
                }"
                placeholder="5455948640"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group label="Şifre" label-for="password">
            <b-form-input
              id="password"
              type="password"
              v-model="editUserRequest.password"
            />
          </b-form-group>

          <b-form-group label="Cinsiyesi" label-for="from">
            <v-select
              id="vue-select"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(gender) => gender.name"
              label="name"
              :options="gender"
              v-model="editUserRequest.gender"
            />
          </b-form-group>
          <b-form-group label="Bilgilendirme" label-for="from">
            <b-form-checkbox v-model="editUserRequest.campaignEmail">
              E-Posta Bilgilendirme
            </b-form-checkbox>
            <b-form-checkbox v-model="editUserRequest.campaignSms">
              Sms Bilgilendirme
            </b-form-checkbox>
            <b-form-checkbox v-model="editUserRequest.campaignPhone">
              Aranarak Bilgilendirme
            </b-form-checkbox>
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              @click="updateUser"
            >
              Güncelle
            </b-button>
            <b-button variant="outline-secondary" @click="hide">
              Vazgeç
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>

    <b-modal v-model="showInfo" title="Excell Kullanıcı Yükleme" hide-footer>
      <p>
        Örnek Şablonu
        <a
          href="https://api.uzaktanokulsistemi.com/sablon/SABLON-GUARDIAN.xlsx"
          target="_blank"
          >buradan</a
        >
        indirebilirsiniz.<br />
        <b
          >Not: Excelde Öğrenci Kullanıcı Adı girilirken öğrencinin "Kullanıcı
          Numarası" Kısmı girilmelidir.</b
        >
      </p>
      <p>
        <b-button
          block
          variant="primary"
          class="font-weight-bolder mb-12"
          @click="$refs.files.click()"
          >YÜKLE</b-button
        >
      </p>
    </b-modal>
    <b-modal v-model="showLoading" centered hide-footer hide-header>
      <p class="text-center">
        <b>Veliler sisteme yükleniyor!<br />Lütfen Bekleyin...</b>
      </p>
    </b-modal>
    <input
      style="display: none"
      type="file"
      id="files"
      ref="files"
      v-on:change="handleFileUploads()"
    />
    <b-modal v-model="showError" title="İşlem Logları" hide-footer>
      <em class="my-4" v-for="(i, key) in logs" :key="key">
        {{ i }}
        <br />
      </em>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BMediaBody,
  BMedia,
  BMediaAside,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.tr";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
export default {
  components: {
    VueGoodTable,
    Cleave,
    BFormCheckbox,
    vSelect,
    ToastificationContent,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BInputGroupPrepend,
    BInputGroup,
  },
  data() {
    return {
      showInfo: false,
      showLoading: false,
      showError: false,
      logs: [],
      gender: [
        {
          name: "ERKEK",
        },
        {
          name: "KADIN",
        },
        {
          name: "BELİRTİLMEMİŞ",
        },
      ],
      fields: [
        {
          field: "createdAt",
          label: "Kayıt Tarihi",
          filterOptions: {
            enabled: true,
            placeholder: "Tarihe Göre Ara",
          },
        },
        { field: "isActive", label: "Durum" },
        {
          field: "firstName",
          label: "Adı",
          filterOptions: {
            enabled: true,
            placeholder: "İsme Göre Ara",
          },
        },
        {
          field: "lastName",
          label: "Soyadı",
          filterOptions: {
            enabled: true,
            placeholder: "Soyisme Göre Ara",
          },
        },
        {
          field: "email",
          label: "E-Posta Adresi",
          filterOptions: {
            enabled: true,
            placeholder: "E-Posta Adresine Göre Ara",
          },
        },
        {
          field: "gsmNumber",
          label: "Telefon Numarası",
          filterOptions: {
            enabled: true,
            placeholder: "Telefona Göre Ara",
          },
        },
        { field: "gender", label: "Cinsiyet" },
        { field: "actions", label: "İşlemler" },
      ],
      users: [],
      branchs: [],
      addUserPanel: false,
      addUserRequest: {
        branchId: 0,
        password: "",
        email: "",
        gsmNumber: "",
        firstName: "",
        lastName: "",
        gender: "",
        idNumber: "",
        campaignEmail: true,
        campaignSms: true,
        campaignPhone: true,
      },
      editUserPanel: false,
      editUserRequest: {
        branchId: 0,
        password: "",
        email: "",
        gsmNumber: "",
        firstName: "",
        lastName: "",
        gender: "",
        idNumber: "",
        campaignEmail: true,
        campaignSms: true,
        campaignPhone: true,
      },
    };
  },
  mounted() {
    this.$root.$on("AddButtonClick", () => {
      this.addUserPanel = true;
    });
    this.$root.$on("AddUploadClick", () => {
      this.showInfo = true;
      // console.log(this.$refs);
      //this.$refs.files.click();
    });
  },
  created() {
    this.getData();
    this.getStudent();
  },
  methods: {
    editUser(user) {
      this.editUserRequest = user;
      this.editUserPanel = true;
    },
    async getData() {
      var users = await this.$http.get("Branch/Guardians");
      this.users = users.data.data;
    },
    myTrim(x) {
      var replaced = x.replace(/\s+/g, "");
      return replaced;
    },
    async getStudent() {
      var branchs = await this.$http.get("Branch/Students");
      this.branchs = branchs.data.data;
    },
    addBranchUser() {
      var request = this.addUserRequest;
      this.$http
        .post("Branch/AddStudentGuardian", {
          studentId: request.branchId,
          password: request.password,
          email: request.email,
          gsmNumber: this.myTrim(request.gsmNumber),
          firstName: request.firstName,
          lastName: request.lastName,
          gender: request.gender,
          idNumber: request.idNumber,
          campaignEmail: true,
          campaignSms: true,
          campaignPhone: true,
        })
        .then((data) => {
          //console.log(data);
          this.getData();
          this.addUserPanel = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "İşlem",
              icon: "BellIcon",
              text: "İşlem Başarılı Kullanıcı Oluşturuldu",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          //console.log(error.response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Hata",
              icon: "BellIcon",
              text: error.response.data.error.message,
              variant: "danger",
            },
          });
        });
    },
    updateUser() {
      var request = this.editUserRequest;
      this.$http
        .put("Branch/UpdateGuardian", {
          guardianId: request.id,
          password: request.password,
          email: request.email,
          gsmNumber: this.myTrim(request.gsmNumber),
          firstName: request.firstName,
          lastName: request.lastName,
          gender: request.gender,
          idNumber: request.idNumber,
          campaignEmail: true,
          campaignSms: true,
          campaignPhone: true,
        })
        .then((data) => {
          //console.log(data);
          this.getData();
          this.editUserPanel = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "İşlem",
              icon: "BellIcon",
              text: "İşlem Başarılı Kullanıcı Güncellendi",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          //console.log(error.response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Hata",
              icon: "BellIcon",
              text: error.response.data.error.message,
              variant: "danger",
            },
          });
        });
    },
    handleFileUploads() {
      var formData = new FormData();
      this.showInfo = false;
      this.showLoading = true;
      formData.append("file", this.$refs.files.files[0]);
      this.$http
        .post("Branch/GuardianUpload", formData)
        .then((data) => {
          this.logs = data.data.data;
          this.showLoading = false;
          this.getData();
          this.showError = true;
          this.$refs.files.value = null;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Hata",
              icon: "BellIcon",
              text: error.response.data.error.message,
              variant: "danger",
            },
          });
        });
    },
    removeUser(user) {
      console.log(user);

      this.$confirm({
        title: "Sil",
        message: `${user.firstName} ${user.lastName} Adlı Veliyi Silmek İstediğinizden Emin misiniz? <b style='color:red'> Silindiğinde öğrencinin bütün dataları temizlenecektir bir daha bu öğrenciye ait raporlamalar yapılmıyacaktır!!!</b>`,
        button: {
          no: "Vazgeç",
          yes: "Sil",
        },
        callback: async (confirm) => {
          if (confirm) {
            await this.$http.delete("Branch/RemoveGuardian/" + user.id);
            this.editUserPanel = false;
            this.getData();
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
